<template>
    <div class="v-100">
        Cerrando sesión...
    </div>
</template>
<script>
export default {
    name: 'cerrar-sesion',
    mounted(){
        this.logout()
    },
    methods: {
        logout(){
            this.$store.commit('logout')
            this.$store.commit('deleteAllItems')
            this.$router.push({name: 'home'})
        }
    }
}
</script>